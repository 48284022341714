
import { defineComponent, PropType, StyleValue } from "vue";
import { AppModalSize } from "@/shared/types/components";
import { AppModalSizeEnum } from "@/shared/enums/components";

const __default__ = defineComponent({
  name: "AppModal",

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String as PropType<AppModalSize>,
      default: "medium",
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    showFooterBorder: {
      type: Boolean,
      default: true,
    },
    showHeaderBorder: Boolean,
    footerGap: {
      type: String,
      default: "8px",
    },
  },

  emits: ["update:modelValue"],

  computed: {
    isFullScreen(): boolean {
      return this.size === "fullscreen";
    },

    appModalClass(): string[] {
      return [
        "app-modal",
        `app-modal--${this.modelValue ? "visible" : "hidden"}`,
      ];
    },

    appModalContentClass(): string[] {
      return [
        "app-modal__content",
        `app-modal__content--${this.modelValue ? "visible" : "hidden"}`,
      ];
    },

    appModalContentHeaderClass() {
      return [
        "app-modal__content__header",
        {
          "app-modal__content__header--bordered": this.showHeaderBorder,
        },
      ];
    },

    appModalContentStyle(): StyleValue {
      return {
        width: AppModalSizeEnum[this.size],
        height: this.isFullScreen ? "100vh" : undefined,
        borderRadius: this.isFullScreen ? "0" : "16px",
        borderWidth: this.isFullScreen ? "0" : "1px",
      };
    },

    appModalContentFooterClass() {
      return [
        "app-modal__content__footer",
        {
          "app-modal__content__footer--bordered": this.showFooterBorder,
        },
      ];
    },
  },

  methods: {
    hideModal(): void {
      this.$emit("update:modelValue", false);
    },

    handleAppModalClick(event: MouseEvent): void {
      if (this.closeOnBackdropClick && event.target === this.$refs.appModal) {
        this.hideModal();
      }
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "79f27f2a": (_ctx.footerGap)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__