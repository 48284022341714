import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass([
      'app-floating-menu-item',
      { 'app-floating-menu-item--selected': _ctx.item.isSelected },
      { 'app-floating-menu-item--disabled': _ctx.item.isDisabled },
    ])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.itemType.component), _mergeProps(_ctx.itemType.bind, {
      class: [
        'app-floating-menu-item__label',
        { 'app-floating-menu-item__label--selected': _ctx.item.isSelected },
        { 'app-floating-menu-item__label--disabled': _ctx.item.isDisabled },
      ]
    }), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.label), 1)
      ]),
      _: 1
    }, 16, ["class"]))
  ], 2))
}