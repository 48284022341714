import { defineStore } from "pinia";
import { AppToastType } from "@/shared/types/components";
import { v4 as uuidv4 } from "uuid";
import { Notification } from "@/shared/types/common";

export default defineStore("notifications", {
  state: () => {
    return {
      notifications: [] as Notification[],
    };
  },

  actions: {
    notify(
      label: string,
      type?: AppToastType,
      duration?: number,
      callback?: (...args: unknown[]) => void
    ) {
      const id = uuidv4();

      const dismiss = () => {
        this.notifications = this.notifications.filter(
          (notification) => notification.id !== id
        );
      };

      const onClick = () => {
        if (callback !== undefined) {
          callback();
        }

        dismiss();
      };

      this.notifications.push({ id, label, type, onClick });

      if (duration !== 0) {
        setTimeout(dismiss, duration || 4000);
      }

      return id;
    },

    removeNotification(notificationId: string) {
      this.notifications = this.notifications.filter(
        (notification) => notification.id !== notificationId
      );
    },
  },
});
