import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63cdb096"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-floating-menu",
  ref: "appFloatingMenu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppFloatingMenuItem = _resolveComponent("AppFloatingMenuItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "content", {}, undefined, true),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass(_ctx.appFloatingMenuListClass),
          style: _normalizeStyle(_ctx.appFloatingMenuListStyle)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createBlock(_component_AppFloatingMenuItem, {
              item: option,
              key: 'app-floating-menu-list-option-' + option.name,
              onClick: ($event: any) => (_ctx.handleOptionClick(option))
            }, null, 8, ["item", "onClick"]))
          }), 128))
        ], 6))
      : _createCommentVNode("", true)
  ], 512))
}