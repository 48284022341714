import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3bf3bde"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-sidebar-item__children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppSidebarItem = _resolveComponent("AppSidebarItem", true)!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass([
      'app-sidebar-item',
      {
        'app-sidebar-item--has-link': _ctx.item.path,
        'app-sidebar-item--child': _ctx.isChild,
        'app-sidebar-item--active': _ctx.isActiveLink,
      },
    ])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.linkElementBinding.component), _mergeProps({
      "link-component": _ctx.linkElementBinding.component
    }, _ctx.linkElementBinding.bind, {
      class: [
        'app-sidebar-item__link',
        { 'app-sidebar-item__link--child': _ctx.isChild },
      ]
    }), {
      default: _withCtx(() => [
        (_ctx.item.icon)
          ? (_openBlock(), _createBlock(_component_AppIcon, {
              key: 0,
              src: _ctx.isActiveLink ? _ctx.item.activeIcon : _ctx.item.icon,
              size: "24px"
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        (_ctx.isSidebarCollapsed)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass([
          'app-sidebar-item__link__name',
          { 'app-sidebar-item__link__name--child': _ctx.isChild },
        ])
            }, _toDisplayString(_ctx.item.name), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16, ["link-component", "class"])),
    (_ctx.isActiveLink && _ctx.item.children?.length && _ctx.isSidebarCollapsed)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
            return (_openBlock(), _createBlock(_component_AppSidebarItem, {
              key: child.name,
              item: child,
              "is-sidebar-collapsed": _ctx.isSidebarCollapsed,
              "is-child": true
            }, null, 8, ["item", "is-sidebar-collapsed"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}