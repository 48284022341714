<template>
  <component :is="iconComponent" v-if="iconComponent" />
</template>

<script setup lang="ts">
import { shallowRef, watchEffect } from "vue";
import { defineAsyncComponent } from "vue";

const props = defineProps<{
  icon: string;
}>();

const iconComponent = shallowRef(null);

watchEffect(async () => {
  if (props.icon) {
    iconComponent.value = await defineAsyncComponent(
      () => import(`@/components/icons/${props.icon}-icon.vue`),
    );
  }
});
</script>
