export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;

  return emailRegex.test(email);
};

export const isBusinessEmail = (email: string): boolean => {
  const businessEmailRegex =
    /^[a-zA-Z0-9.%+-]+@(?!gmail.)(?!mail.)(?!protonmail.)(?!yahoo.)(?!hotmail.)(?!zohomail.)(?!aol.)(?!live.)(?!outlook.)(?!icloud.)(?!gmx.)(?!hey.)(?!yandex.)(?!titanota.)(?!dealroom.)(?!tensoriel.)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/;

  return businessEmailRegex.test(email);
};

export const isValidPassword = (password: string): boolean => {
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':",.<>/?\\|`~-]).{8,}$/;

  return passwordPattern.test(password);
};

export const isEmpty = (payload: unknown): boolean => {
  return (
    payload === null ||
    payload === undefined ||
    (typeof payload === "string" && payload.trim().length === 0) ||
    (Array.isArray(payload) && payload.length === 0) ||
    (typeof payload === "object" &&
      !Array.isArray(payload) &&
      Object.keys(payload).length === 0)
  );
};

export const isBetween = (num: number, low: number, high: number): boolean => {
  return num > low && num < high;
};

export const isValidUrl = (url: string): boolean => {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s]{2,}$/i;
  return regex.test(url);
};

export const isValidLinkedinUrl = (url: string): boolean => {
  const linkedInRegex =
    /^https?:\/\/(?:[a-z]{2}\.|www\.)?linkedin\.com\/(?:in|company)\/[a-zA-Z0-9_%\-®]+\/?$/;

  return linkedInRegex.test(url);
};

export const isYearValid = (year: string): boolean => {
  return (
    year.length === 4 &&
    !isNaN(Number(year)) &&
    Number(year) <= new Date().getFullYear()
  );
};

export const isValidJSON = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (_) {
    return false;
  }
  return true;
};

export const trimRichText = (content: string): string =>
  content.replace(/(<\/?(?!iframe)[^>]+>)/gi, "").trim();

export const isRichTextEmpty = (content: string): boolean =>
  !trimRichText(content);
