<template>
  <component
    :is="buttonComponent.component"
    :disabled="isDisabled || isLoading"
    :type="nativeType"
    :class="[
      'app-button',
      `app-button--${isLoading || isDisabled ? 'disabled' : type}`,
    ]"
    v-bind="{ ...$attrs, ...buttonComponent.bind }"
    @mouseover="setIsHover(true)"
    @mouseout="setIsHover(false)"
  >
    <slot name="left-icon" />
    <slot>
      <template v-if="icon">
        <AppIcon
          v-if="isIconImported(icon)"
          :src="iconSrc"
          :size="iconSize"
          :class="iconClass"
        />
        <BaseIcon v-else :icon="icon" :class="iconClass" :size="iconSize" />
      </template>
      <span v-if="buttonLabel" class="app-button__label" :class="labelClass">
        {{ buttonLabel }}
      </span>
      <template v-if="trailingIcon">
        <AppIcon
          v-if="isIconImported(trailingIcon)"
          :src="trailingIconSrc"
          :size="trailingIconSize"
          :class="[
            iconClass,
            {
              'app-button__trailing-icon':
                shiftTrailingIconOnHover && isHovered,
            },
          ]"
        />
        <BaseIcon
          v-else
          :icon="trailingIcon"
          :class="iconClass"
          :size="trailingIconSize"
        />
      </template>
    </slot>
  </component>
</template>

<script setup lang="ts">
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import { AppButtonType } from "@/shared/types/components";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { isExternalLink } from "@/shared/helpers/isExternalLink/isExternalLink";
import { ref, computed } from "vue";

const { getNewTabEnabled } = useWebCustomerStore();

const props = withDefaults(
  defineProps<{
    iconSize?: string;
    trailingIconSize?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    width?: string;
    height?: string;
    icon?: string;
    label?: string;
    color?: string;
    iconHover?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    type?: AppButtonType;
    nativeType?: "button" | "reset" | "submit";
    trailingIcon?: string;
    trailingIconHover?: string;
    shiftTrailingIconOnHover?: boolean;
    to?: string;
    href?: string;
    targetBlank?: boolean;
    labelClass?: string | Array<string> | Record<string, boolean>;
    iconClass?: string | Array<string> | Record<string, boolean>;
  }>(),
  {
    iconSize: "16px",
    trailingIconSize: "16px",
    padding: "6px 16px",
    fontWeight: "300",
    type: "primary",
    nativeType: "button",
    width: "fit-content",
  },
);

const isHovered = ref(false);

const buttonLabel = computed((): string | undefined => {
  return props.isLoading ? "Loading..." : props.label;
});

const isLink = computed((): boolean => {
  return !!props.to || !!props.href;
});

const buttonComponent = computed(() => {
  if (isLink.value) {
    let target: "_blank" | "_self" = "_self";

    if (
      props.targetBlank ||
      isExternalLink(props.href || props.to || "") ||
      getNewTabEnabled
    ) {
      target = "_blank";
    }

    if (props.href) {
      return {
        component: "a",
        bind: {
          href: props.href,
          target,
        },
      };
    } else {
      return {
        component: "router-link",
        bind: {
          to: props.to,
          target,
        },
      };
    }
  } else {
    return {
      component: "button",
      bind: {},
    };
  }
});

//TODO: remove this after completing update everywhere
const iconSrc = computed((): string | undefined => {
  return isHovered.value && !props.isDisabled && props.iconHover
    ? props.iconHover
    : props.icon;
});

const trailingIconSrc = computed((): string | undefined => {
  return isHovered.value && !props.isDisabled && props.trailingIconHover
    ? props.trailingIconHover
    : props.trailingIcon;
});

const isIconImported = (icon: string) =>
  icon?.startsWith("data:image/png") ||
  icon?.startsWith("/img/") ||
  icon?.includes("http");

const setIsHover = (value: boolean) => {
  isHovered.value = value;
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-button {
  display: flex;
  justify-content: center;
  gap: rem(8px);
  border-radius: rem(16px);
  letter-spacing: rem(1px);
  max-height: rem(48px);
  align-items: center;
  border: unset;
  transition: background-color 0.15s ease-out;
  width: v-bind(width);
  height: v-bind(height);
  padding: v-bind(padding);
  color: v-bind(color);

  &:hover &__trailing-icon {
    transform: translateX(rem(4px));
    transition: 0.15s ease-out;
  }

  &:not(&--disabled) {
    cursor: pointer;
  }

  &--primary,
  &--bordered:hover {
    background-color: $primary;
  }

  &--danger {
    background-color: $danger;

    &:hover {
      box-shadow: inset 0 0 0 1px $danger;
      color: $danger;
    }
  }

  &--disabled {
    background-color: $disabled;
  }

  &__label {
    white-space: nowrap;
    color: currentColor;
    font-size: v-bind(fontSize);
    font-weight: v-bind(fontWeight);
  }

  &--primary,
  &--danger,
  &--disabled,
  &--bordered:hover {
    color: $white;
  }

  &--empty,
  &--bordered,
  &--primary:hover {
    color: $primary;
  }

  &--bordered,
  &--primary:hover {
    box-shadow: inset 0 0 0 1px $border;
  }

  &--empty,
  &--bordered,
  &--primary:hover,
  &--danger:hover {
    background-color: transparent;
  }

  &:focus-visible {
    outline: unset;
  }
}
</style>
