import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stores";
import "@/shared/helpers/prototypes/String/string.extensions";
import "@/shared/helpers/prototypes/Number/number.extension";
import "@/styles/common.scss";
import VueApexCharts from "vue3-apexcharts";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";

const webCustomerStore = useWebCustomerStore(store);

webCustomerStore
  .getLoggedWebCustomer()
  .catch(async () => {
    const currentRoute = window.location.pathname;
    if (currentRoute !== "/login") {
      sessionStorage.setItem("redirectedFrom", currentRoute);
    }

    if (router.resolve(window.location.pathname).meta.layout) {
      await router.push(window.location.pathname);
    } else {
      await router.push("/login");
    }
  })
  .finally(() => {
    createApp(App).use(router).use(store).use(VueApexCharts).mount("#app");
  });
