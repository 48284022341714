import { defineStore } from "pinia";
import { WebCustomer, Currency } from "@/shared/types/common";
import authenticationService from "@/shared/services/authenticationService/authenticationService";
import profileService from "@/shared/services/profileService/profileService";

export default defineStore("webCustomer", {
  state: () => {
    return {
      webCustomer: {} as WebCustomer,
      isAuth: false,
    };
  },

  getters: {
    isUserAdmin: (state) => state.webCustomer.admin,

    getCurrency: (state) => state.webCustomer.selectedCurrency || Currency.EUR,

    getNewTabEnabled: (state) => state.webCustomer.newTabEnabled,

    showSubscriptionBanner: (state) => {
      if (state.webCustomer.freeTrial) return true;

      const twoMonthsInMiliseconds = 60 * 60 * 1000 * 2 * 30 * 24;

      return (
        new Date(state.webCustomer.endDate).getTime() - new Date().getTime() <
          twoMonthsInMiliseconds && !state.webCustomer.freeTrial
      );
    },

    isCurrencyEuro(): boolean {
      return this.getCurrency === Currency.EUR;
    },

    getCurrencySign(): string {
      return this.isCurrencyEuro ? "€" : "$";
    },
  },

  actions: {
    async getLoggedWebCustomer() {
      const response = await authenticationService.getLoggedWebCustomer();

      if (response) {
        this.webCustomer = response.data;
        this.isAuth = true;
      }

      return response;
    },

    async logoutWebCustomer() {
      try {
        await authenticationService.signOut();
      } catch (e) {
        console.error(e);
      } finally {
        this.isAuth = false;
      }
    },

    async setPageLimit(limit: number) {
      const response = await profileService.setLimit(limit);

      if (response) {
        this.webCustomer.pageSize = limit;
      }

      return response;
    },

    async toggleOpenInNewTab() {
      const response = await profileService.toggleOpenInNewTab();

      if (response) {
        this.webCustomer.newTabEnabled = !this.webCustomer.newTabEnabled;
      }

      return response;
    },

    async setCurrency(currency: Currency) {
      const response = await profileService.setCurrency(currency);

      if (response) {
        this.webCustomer.selectedCurrency = currency;
      }

      return response;
    },

    async removeAffinityKey() {
      const response = await profileService.removeAffinityKey();

      if (response) {
        this.webCustomer.affinityAPIKey = "";
      }

      return response;
    },

    async updateAffinityKey(apiKey: string) {
      const response = await profileService.updateAffinityKey(apiKey);

      if (response) {
        this.webCustomer.affinityAPIKey = apiKey;
      }

      return response;
    },

    changePassword(
      token: string,
      currentPassword: string,
      newPassword: string,
      confirmedPassword: string,
    ) {
      return authenticationService.changePassword(
        token,
        currentPassword,
        newPassword,
        confirmedPassword,
      );
    },

    async toggleTwoFactorAuth(webCustomerID: number) {
      const response = await profileService.toggleTwoFactorAuth(webCustomerID);

      if (response) {
        this.webCustomer.is2FAEnabled = !this.webCustomer.is2FAEnabled;
      }

      return response;
    },
  },
});
