import { Currency } from "@/shared/types/common";
import axios from "@/plugins/axios/axios";
import { AxiosResponse } from "axios";
import { TeamMember } from "@/shared/types/profileClaim";

const getTeamMemberEmails = (
  teamId: number,
): Promise<AxiosResponse<TeamMember[]>> => {
  return axios.get(`/teams/teamMembers/${teamId}`);
};

const setLimit = (pageSize: number) => {
  return axios.put("/webcustomers/pageSize", { pageSize });
};

const setCurrency = (currency: Currency) => {
  return axios.put("/webcustomers/selectedCurrency", { currency });
};

const toggleOpenInNewTab = (): Promise<AxiosResponse<boolean>> => {
  return axios.put("/webcustomers/newTab");
};

const removeAffinityKey = () => {
  return axios.delete("/affinity/removeAPIKey");
};

const updateAffinityKey = (apiKey: string) => {
  return axios.post("/affinity/checkAndUpdateAPIKey", { value: apiKey });
};

function toggleTwoFactorAuth(
  webCustomerID: number,
): Promise<AxiosResponse<boolean>> {
  return axios.put(`/2fa/${webCustomerID}`);
}

export default {
  getTeamMemberEmails,
  setLimit,
  setCurrency,
  toggleOpenInNewTab,
  removeAffinityKey,
  updateAffinityKey,
  toggleTwoFactorAuth,
};
