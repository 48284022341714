
import { defineComponent, StyleValue } from "vue";
import {
  menuBlack,
  netZeroInsightsLogoHighRes,
  dashboardBlack,
  dashboardGreen,
  hearthGreen,
  hearthBlack,
  savedGreen,
  savedBlack,
  settingsGreen,
  settingsBlack,
  magnifierGrey,
  plusIconGreen,
  searchBlack,
  searchGreen,
  editGreen,
  editBlack,
  adminBlack,
  adminGreen,
  medalBlack,
  taxonomyBlack,
  taxonomyGreen,
  marketResearch,
  marketResearchGreen,
} from "@/shared/constants/icons";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import {
  AppFloatingMenuOption,
  AppSidebarLink,
} from "@/shared/types/components";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import organizationsService from "@/shared/services/organizationsService/organizationsService";
import { WebCustomer } from "@/shared/types/common";
import AppSidebar from "@/components/app/AppSidebar/AppSidebar.vue";
import { mapStores, storeToRefs } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { useSearchResults } from "@/shared/composables/useSearchResults/useSearchResults";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import AppNewCompanyModal from "@/components/dashboard/AppNewCompanyModal/AppNewCompanyModal.vue";
import { NewCompanyModel } from "@/shared/types/organization";
import { defaultServerErrorMessage } from "@/shared/constants/errors";
import { AxiosError } from "axios";
import AppTooltip from "@/components/app/AppTooltip/AppTooltip.vue";
import { ClaimProfileSteps } from "@/shared/types/profileClaim";
import { ADMIN_PANEL_TABS } from "@/shared/types/admin";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";

const __default__ = defineComponent({
  name: "AppLayout",

  components: {
    AppInput,
    AppFloatingMenu,
    AppSidebar,
    AppButton,
    AppNewCompanyModal,
    AppTooltip,
    AppExpiryBanner,
    AppLink,
  },

  setup() {
    const { searchResultsText } = useSearchResults();
    const { getNewTabEnabled } = storeToRefs(useWebCustomerStore());

    return { searchResultsText, getNewTabEnabled };
  },

  data() {
    return {
      appFloatingMenuModel: false,
      netZeroInsightsLogoHighRes,
      magnifierGrey,
      showAddCompanyModal: false,
      searchText: "",
      isLoading: false,
      plusIconGreen,
      abortController: undefined as AbortController | undefined,
      navbarHeight: 64,
      contentPaddingTop: "112px",
      headerLineTopPosition: `${this.navbarHeight}px`,
      contentPaddingTopForSpecificPages: `${this.navbarHeight}px`,
      navbarTopPosition: "0",
      expiryBannerHeight: 32,
    };
  },

  methods: {
    handleAppFloatingMenuClick() {
      this.appFloatingMenuModel = !this.appFloatingMenuModel;
    },

    handleDisplayCompanyModal() {
      this.showAddCompanyModal = true;
    },

    handleAddCompanyClick() {
      this.handleDisplayCompanyModal();
    },

    async handleAddNewCompany(newCompany: NewCompanyModel) {
      try {
        this.isLoading = true;
        const newAddedCompany =
          await organizationsService.addNewCompany(newCompany);

        if (this.webCustomerStore.isUserAdmin) {
          if (this.getNewTabEnabled) {
            window.open(
              `/organization/${newAddedCompany.data.directURL}`,
              "_blank",
            );
          } else {
            this.$router.push(
              `/organization/${newAddedCompany.data.directURL}`,
            );
          }
        }

        this.showAddCompanyModal = false;
      } catch (e) {
        const errorMessage =
          (e as AxiosError).response?.status === 400
            ? "Invalid URL"
            : defaultServerErrorMessage;

        this.notificationsStore.notify(errorMessage, "danger", 3000);
      } finally {
        this.isLoading = false;
      }
    },

    goSearchResultsPage() {
      if (this.$route.name !== "SearchResultsView") {
        this.$router.push({ name: "SearchResultsView" });
      }
    },
  },

  computed: {
    ...mapStores(useWebCustomerStore, useNotificationsStore),

    webCustomer(): WebCustomer {
      return this.webCustomerStore.webCustomer;
    },

    contentStyle(): StyleValue {
      const path = this.$route.path as string;
      const name = this.$route.name as string;

      if ((this.$route.name as string) === "DeepDiveDetailsView") {
        return {
          paddingTop: this.contentPaddingTopForSpecificPages,
          paddingLeft: 0,
          paddingBottom: 0,
        };
      } else if (
        [
          "OrganizationDetails",
          "OrganisationDetails",
          "InvestorDetails",
        ].includes(name)
      ) {
        return {
          paddingTop: this.contentPaddingTopForSpecificPages,
          paddingLeft: 0,
        };
      } else if (
        name === "Portfolio" ||
        path.includes("/organizations") ||
        path.includes("/funding-rounds") ||
        (path.includes("/search") && !path.includes("/searches")) ||
        path === "/investors" ||
        path.includes("/admin-panel") ||
        path.includes("/similar-companies")
      ) {
        return {
          paddingTop: this.contentPaddingTopForSpecificPages,
          paddingBottom: 0,
        };
      } else if (
        ["/lists/organisations", "/lists/investors", "/searches"].includes(path)
      ) {
        return {
          overflowY: "scroll",
        };
      } else {
        return {};
      }
    },

    userInitials(): string {
      return this.webCustomer &&
        this.webCustomer.name &&
        this.webCustomer.surname
        ? `${this.webCustomer.name[0]}${this.webCustomer.surname[0]}`.toUpperCase()
        : "";
    },

    appFloatingMenuOptions(): AppFloatingMenuOption[] {
      return [
        {
          name: "extendSubscription",
          label: "Extend subscription",
          link: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
          isSelected: ![
            "/user/profile",
            "/lists",
            "/searches",
            "/admin-panel",
          ].includes(this.$route.path),
        },
        {
          name: "account",
          label: "Account",
          link: "/user/profile",
          isSelected: this.$route.path === "/user/profile",
        },
        ...(this.webCustomerStore.isUserAdmin
          ? [
              {
                label: "Admin panel",
                link: "/admin-panel",
                name: "admin-panel",
                isSelected: this.$route.path === "/admin-panel",
              },
            ]
          : []),
        {
          name: "portfolios",
          label: "Portfolios",
          link: "/lists",
          isSelected: this.$route.path === "/lists",
        },
        {
          name: "savedSearches",
          label: "Saved searches",
          link: "/searches",
          isSelected: this.$route.path === "/searches",
        },
        ...(this.webCustomer.profileClaimToken
          ? [
              {
                name: "editProfile",
                label: "Edit company profile",
                link: this.claimProfileURL,
                isSelected:
                  this.$route.name === "OrganizationDetails" &&
                  this.webCustomer.profileClaimClientID !== undefined &&
                  this.$route.path.includes(
                    this.webCustomer.profileClaimClientID.toString(),
                  ),
              },
            ]
          : []),
        {
          name: "logOut",
          label: "Log out",
          link: "/logout",
        },
      ];
    },

    links(): AppSidebarLink[] {
      return [
        { icon: menuBlack, name: "Menu" },
        {
          icon: dashboardBlack,
          activeIcon: dashboardGreen,
          name: "Dashboard",
          path: "/dashboard",
        },
        {
          icon: searchBlack,
          activeIcon: searchGreen,
          name: "Search",
          path: "/search/",
          children: [
            {
              name: "Recently raised",
              path: "/search/recently-raised",
            },
            {
              name: "Recently viewed",
              path: "/search/recently-viewed",
            },
            {
              name: "Top viewed",
              path: "/search/top-viewed",
            },
            {
              name: "Recent deals",
              path: "/search/recent-deals",
            },
            {
              name: "Largest deals",
              path: "/search/largest-deals",
            },
            {
              name: "Exits",
              path: "/search/exit-deals",
            },
          ],
        },
        {
          icon: taxonomyBlack,
          activeIcon: taxonomyGreen,
          name: "Taxonomy",
          path: "/taxonomy",
        },
        {
          icon: marketResearch,
          activeIcon: marketResearchGreen,
          name: "Market Research",
          path: "/market-research",
        },
        {
          icon: hearthBlack,
          activeIcon: hearthGreen,
          name: "Lists",
          path: "/lists",
          children: [
            {
              name: "Organisations",
              path: "/lists/organisations",
            },
            {
              name: "Investors",
              path: "/lists/investors",
            },
          ],
        },
        {
          icon: savedBlack,
          activeIcon: savedGreen,
          name: "My searches",
          path: "/searches",
        },
        ...(this.webCustomer.freelanceStackRewardCoupon
          ? [
              {
                icon: medalBlack,
                activeIcon: medalBlack,
                name: "Rewards & discounts",
                path: this.webCustomer.freelanceStackRewardCoupon,
                isExternal: true,
              },
            ]
          : []),
        {
          icon: settingsBlack,
          activeIcon: settingsGreen,
          name: "Settings",
          path: "/user/profile",
        },
        ...(this.webCustomer.profileClaimToken
          ? [
              {
                icon: editBlack,
                activeIcon: editGreen,
                name: "Edit company profile",
                path: this.claimProfileURL,
              },
            ]
          : []),
        ...(this.webCustomerStore.isUserAdmin
          ? [
              {
                icon: adminBlack,
                activeIcon: adminGreen,
                name: "Admin panel",
                path: "/admin-panel",
                children: [
                  {
                    name: "Startups Submission",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.STARTUP_SUBMISSION}`,
                  },
                  {
                    name: "Taxonomy Tags",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_TAGS}`,
                  },
                  {
                    name: "Taxonomy Tags Types",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_TYPES}`,
                  },
                  {
                    name: "Taxonomy Tags Families",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_FAMILIES}`,
                  },
                  {
                    name: "Taxonomy Items",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_ITEMS}`,
                  },
                  {
                    name: "Taxonomy Relations",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_RELATIONS}`,
                  },
                  {
                    name: "Web Customers",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.WEB_CUSTOMERS}`,
                  },
                  {
                    name: "Market Reports",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.MARKET_REPORT}`,
                  },
                ],
              },
            ]
          : []),
      ];
    },

    claimProfileURL(): string {
      return `/claim-profile/${this.webCustomer.profileClaimToken}/${ClaimProfileSteps.BASIC_INFORMATION}`;
    },
  },

  watch: {
    $route() {
      const appContent = this.$refs["app-content"] as HTMLDivElement;

      if (appContent) {
        appContent.scrollTop = 0;
      }
    },

    "webCustomerStore.showSubscriptionBanner": {
      immediate: true,
      handler(showSubscriptionBanner) {
        if (showSubscriptionBanner) {
          this.navbarTopPosition = `${this.expiryBannerHeight}px`;
          this.headerLineTopPosition = `${this.navbarHeight + this.expiryBannerHeight}px`;
          this.contentPaddingTop = `${112 + this.expiryBannerHeight}px`;
        } else {
          this.navbarTopPosition = "0";
          this.headerLineTopPosition = `${this.navbarHeight}px`;
          this.contentPaddingTop = "112px";
        }
        this.contentPaddingTopForSpecificPages = this.headerLineTopPosition;
      },
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2d1b7eee": (_ctx.navbarTopPosition),
  "4f454f46": (_ctx.contentPaddingTop),
  "3fa05e3d": (_ctx.headerLineTopPosition)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__