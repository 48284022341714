<template>
  <div :class="appModalClass" ref="appModal" @mousedown="handleAppModalClick">
    <div :class="appModalContentClass" :style="appModalContentStyle">
      <div class="app-modal__content__sidebar" v-if="$slots.sidebar">
        <slot name="sidebar" :hideModal="hideModal" />
      </div>
      <div class="app-modal__content__container">
        <div :class="appModalContentHeaderClass" v-if="$slots.header">
          <slot name="header" :hideModal="hideModal" />
        </div>
        <div class="app-modal__content__body" v-if="$slots.body">
          <slot name="body" :hideModal="hideModal" />
        </div>
        <div :class="appModalContentFooterClass" v-if="$slots.footer">
          <slot name="footer" :hideModal="hideModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, StyleValue } from "vue";
import { AppModalSize } from "@/shared/types/components";
import { AppModalSizeEnum } from "@/shared/enums/components";

export default defineComponent({
  name: "AppModal",

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String as PropType<AppModalSize>,
      default: "medium",
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    showFooterBorder: {
      type: Boolean,
      default: true,
    },
    showHeaderBorder: Boolean,
    footerGap: {
      type: String,
      default: "8px",
    },
  },

  emits: ["update:modelValue"],

  computed: {
    isFullScreen(): boolean {
      return this.size === "fullscreen";
    },

    appModalClass(): string[] {
      return [
        "app-modal",
        `app-modal--${this.modelValue ? "visible" : "hidden"}`,
      ];
    },

    appModalContentClass(): string[] {
      return [
        "app-modal__content",
        `app-modal__content--${this.modelValue ? "visible" : "hidden"}`,
      ];
    },

    appModalContentHeaderClass() {
      return [
        "app-modal__content__header",
        {
          "app-modal__content__header--bordered": this.showHeaderBorder,
        },
      ];
    },

    appModalContentStyle(): StyleValue {
      return {
        width: AppModalSizeEnum[this.size],
        height: this.isFullScreen ? "100vh" : undefined,
        borderRadius: this.isFullScreen ? "0" : "16px",
        borderWidth: this.isFullScreen ? "0" : "1px",
      };
    },

    appModalContentFooterClass() {
      return [
        "app-modal__content__footer",
        {
          "app-modal__content__footer--bordered": this.showFooterBorder,
        },
      ];
    },
  },

  methods: {
    hideModal(): void {
      this.$emit("update:modelValue", false);
    },

    handleAppModalClick(event: MouseEvent): void {
      if (this.closeOnBackdropClick && event.target === this.$refs.appModal) {
        this.hideModal();
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-modal {
  display: flex;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $backdrop;
  overflow-y: auto;
}

.app-modal__content {
  background-color: $white;
  margin: auto;
  border: 1px solid $borderAlternative;
  display: flex;
}

.app-modal,
.app-modal__content {
  transition: 0.4s ease;
}

.app-modal--visible {
  visibility: visible;
}

.app-modal--hidden {
  visibility: hidden;
}

.app-modal__content--visible {
  transform: unset;
}

.app-modal__content--hidden {
  transform: translateY(-50%);
}

.app-modal--visible,
.app-modal__content--visible {
  opacity: 1;
}

.app-modal--hidden,
.app-modal__content--hidden {
  opacity: 0;
}

.app-modal__content__header,
.app-modal__content__body,
.app-modal__content__footer {
  padding: rem(25px);
}

.app-modal__content__sidebar {
  padding: rem(36px) rem(15px);
}

.app-modal__content__header {
  padding-bottom: 0;
}

.app-modal__content__header--bordered {
  border-bottom: 1px solid $border;
  padding-bottom: rem(20px);
}

.app-modal__content__header :deep(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.app-modal__content__body {
  overflow-y: auto;
}

.app-modal__content__footer {
  gap: v-bind(footerGap);
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  padding: rem(15px) rem(25px);
}

.app-modal__content__footer--bordered {
  border-top: 1px solid $border;
}

.app-modal__content > div:not(.app-modal__content__sidebar) {
  flex: 1;
  overflow: hidden;
}

.app-modal__content__sidebar {
  border-right: 1px solid $border;
}

.app-modal__content__container {
  display: flex;
  flex-direction: column;
}
</style>
