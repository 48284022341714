import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-efd528c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-sidebar__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSidebarItem = _resolveComponent("AppSidebarItem")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(['app-sidebar', { 'app-sidebar--expanded': _ctx.isSidebarHovered }]),
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSidebarMouseOver && _ctx.handleSidebarMouseOver(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSidebarMouseLeave && _ctx.handleSidebarMouseLeave(...args)))
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (item) => {
        return (_openBlock(), _createBlock(_component_AppSidebarItem, {
          key: item.name,
          item: item,
          "is-sidebar-collapsed": _ctx.isSidebarHovered
        }, null, 8, ["item", "is-sidebar-collapsed"]))
      }), 128))
    ])
  ], 34))
}