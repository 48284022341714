import axios from "@/plugins/axios/axios";
import { WebCustomer } from "@/shared/types/common";
import { AxiosResponse } from "axios";

function signIn(username: string, password: string) {
  const formData = new FormData();

  formData.append("username", username);
  formData.append("password", password);

  return axios.post("/auth/login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function signUp(user: {
  email: string;
  name: string;
  password: string;
  phone: string;
  surname: string;
  termsAndConditions: boolean;
}) {
  return axios.post("/auth/signup", user);
}

function signOut() {
  return axios.get("/auth/logout");
}

function getLoggedWebCustomer(): Promise<AxiosResponse<WebCustomer>> {
  return axios.get("/auth/loggedWebCustomer");
}

function confirmEmail(token: string): Promise<AxiosResponse<string>> {
  return axios.get(`/confirmation/confirm-email/${token}`);
}

function resetPassword(
  token: string,
  newPassword1: string,
  newPassword2: string,
) {
  return axios.post("/user/forgot-password/change", {
    token,
    newPassword1,
    newPassword2,
  });
}

function sendForgotPasswordEmail(email: string) {
  return axios.post("/user/forgot-password/send-email", { email });
}

function changePassword(
  token: string,
  currentPassword: string,
  newPassword: string,
  confirmedPassword: string,
) {
  return axios.post("/user/forgot-password/change", {
    token,
    currentPassword,
    newPassword1: newPassword,
    newPassword2: confirmedPassword,
  });
}

function changeExpiredPassword(
  token: string,
  currentPassword: string,
  newPassword1: string,
  newPassword2: string,
) {
  return axios.post("/password/reset", {
    token,
    currentPassword,
    newPassword1,
    newPassword2,
  });
}

function confirmTwoFactorAuthCode(code: number) {
  return axios.get("/2fa/confirm", {
    params: {
      code,
    },
  });
}

export default {
  signIn,
  signUp,
  signOut,
  changePassword,
  resetPassword,
  sendForgotPasswordEmail,
  getLoggedWebCustomer,
  confirmEmail,
  changeExpiredPassword,
  confirmTwoFactorAuthCode,
};
