
import AppInformation from "@/components/app/AppInformation/AppInformation.vue";
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "CompanyInfoSectionItem",

  components: {
    AppInformation,
  },

  props: {
    isAlignCenter: {
      type: Boolean,
      default: false,
    },

    information: String,

    isRequired: Boolean,

    width: {
      type: String,
      default: "180px",
    },

    gap: {
      type: String,
      default: "50px",
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1a7ced18": (_ctx.gap),
  "4969f8e4": (_ctx.width)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__