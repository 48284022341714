<template>
  <li
    :class="[
      'app-floating-menu-item',
      { 'app-floating-menu-item--selected': item.isSelected },
      { 'app-floating-menu-item--disabled': item.isDisabled },
    ]"
  >
    <component
      :is="itemType.component"
      v-bind="itemType.bind"
      :class="[
        'app-floating-menu-item__label',
        { 'app-floating-menu-item__label--selected': item.isSelected },
        { 'app-floating-menu-item__label--disabled': item.isDisabled },
      ]"
    >
      {{ item.label }}
    </component>
  </li>
</template>

<script lang="ts">
import { AppFloatingMenuOption } from "@/shared/types/components";
import { defineComponent, PropType } from "vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { isExternalLink } from "@/shared/helpers/isExternalLink/isExternalLink";

export default defineComponent({
  name: "AppFloatingMenuItem",

  props: {
    item: {
      type: Object as PropType<AppFloatingMenuOption>,
      required: true,
    },
  },

  setup() {
    const { getNewTabEnabled } = useWebCustomerStore();

    return { getNewTabEnabled };
  },

  computed: {
    itemType() {
      if (this.item.link) {
        if (isExternalLink(this.item.link)) {
          return {
            component: "a",
            bind: {
              href: this.item.link,
              target: this.getNewTabEnabled ? "_blank" : "_self",
            },
          };
        } else {
          return {
            component: "router-link",
            bind: {
              to: this.item.link,
              target:
                this.getNewTabEnabled && this.item.link !== "/logout"
                  ? "_blank"
                  : "_self",
            },
          };
        }
      } else {
        return {
          component: "span",
        };
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-floating-menu-item {
  padding: rem(6px) rem(32px) rem(6px) rem(16px);
  cursor: pointer;
  box-sizing: border-box;
}

.app-floating-menu-item--disabled {
  cursor: default;
}
.app-floating-menu-item:hover:not(
    .app-floating-menu-item--selected,
    .app-floating-menu-item--disabled
  ) {
  background-color: $hover;
}

.app-floating-menu-item--selected {
  background-color: $primary;
}

.app-floating-menu-item__label {
  color: $black;
  font-weight: 400;
  display: block;
  line-height: rem(22px);
}

.app-floating-menu-item__label--selected {
  color: $white;
}

.app-floating-menu-item__label--disabled {
  color: $disabledBackground;
}
</style>
