import { MainFilter } from "@/shared/types/filters";

export enum SavedSearchType {
  COMPANIES = "COMPANIES",
  FUNDING_ROUNDS = "FUNDING_ROUNDS",
  INVESTORS = "INVESTORS",
}

export interface EmailFrequency {
  id: number;
  label: "Daily" | "Weekly" | "Bi-Weekly" | "Monthly" | "None";
  definition?: string;
}

export interface WCSavedSearch {
  id: number;
  creationDate: string;
  lastSearchedDate?: string;
  name: string;
  updatesFrequency: EmailFrequency;
  searchType: SavedSearchType;
  affinitySyncing: boolean;
  affinityListID?: number;
  affinityLastSync?: string;
  affinityLinkID?: number;
  notification: boolean;
  mainFilter?: MainFilter;
}

export interface SavedSearchModel {
  name: string;
  searchType: SavedSearchType;
  notification: boolean;
  updatesFrequency: EmailFrequency;
  mainFilter?: MainFilter;
}

export enum PortfolioType {
  ORGANIZATION = "organization",
  INVESTOR = "investor",
}
