import axios from "@/plugins/axios/axios";
import { AxiosResponse } from "axios";
import {
  AffinityList,
  AffinitySyncField,
  PushCompanyToAffinity,
  PushableColumn,
  TBInvestorPortfolio,
  TBPortfolio,
  WCInvestorPortfolio,
  WCPortfolio,
} from "@/shared/types/portfolios";
import { MainFilter } from "@/shared/types/filters";
import { AffinityLink } from "@/shared/types/portfolios";

function getWebCustomerPortfolios(): Promise<AxiosResponse<TBPortfolio[]>> {
  return axios.get("/portfolio");
}

function createTbPortfolio(
  portfolioName: string,
): Promise<AxiosResponse<TBPortfolio>> {
  return axios.post("/portfolio", { portfolioName });
}

function deleteTbPortfolio(portfolioId: number) {
  return axios.delete(`/portfolio/${portfolioId}`);
}

function renameTbPortfolio(
  portfolioID: number,
  portfolioName: string,
): Promise<AxiosResponse<TBPortfolio>> {
  return axios.put("/portfolio", {
    portfolioID,
    portfolioName,
  });
}

function removeCompanyFromPortfolio(portfolioId: number, companyId: number) {
  return axios.delete(`/portfolio/${portfolioId}/${companyId}`);
}

function addCompaniesToPortfolios(clientIDs: number[], portfolioIDs: number[]) {
  return axios.post("/portfolio/addCompanies", {
    portfolioIDs,
    clientIDs,
  });
}

function addCompanyToPortfolio(clientID: number, portfolioID: number) {
  return axios.post(`/portfolio/${portfolioID}`, clientID);
}

function getWebCustomerWcPortfolios(
  clientIDs: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<WCPortfolio[]>> {
  return axios.post("/portfolio/findWCPortfolioCompanies", clientIDs, {
    signal: abortSignal,
  });
}

function bulkAddCompaniesToPortfolios(
  portfolioId: number,
  mainFilter: MainFilter,
) {
  return axios.post(`/portfolio/bulk-add/${portfolioId}`, mainFilter);
}

function bulkAddFundingRoundsCompaniesToPortfolios(
  portfolioId: number,
  mainFilter: MainFilter,
) {
  return axios.post(
    `/portfolio/bulk-add-funding-rounds/${portfolioId}`,
    mainFilter,
  );
}

function syncAffinityList(
  portfolioID: number,
): Promise<AxiosResponse<boolean>> {
  return axios.get(`/affinity/updateList/${portfolioID}`);
}

function getAffinityLists(): Promise<AxiosResponse<AffinityList[]>> {
  return axios.get("/affinity/lists");
}

function getInvestorPortfolios(): Promise<
  AxiosResponse<TBInvestorPortfolio[]>
> {
  return axios.get("/investorPortfolio");
}

function createInvestorPortfolio(
  portfolioName: string,
): Promise<AxiosResponse<TBInvestorPortfolio>> {
  return axios.post("/investorPortfolio", { portfolioName });
}

function deleteInvestorPortfolio(portfolioId: number) {
  return axios.delete(`/investorPortfolio/${portfolioId}`);
}

function getWCInvestorPortfolios(
  investorIDs: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<WCInvestorPortfolio[]>> {
  return axios.post(
    "/investorPortfolio/findWCPortfolioInvestors",
    investorIDs,
    {
      signal: abortSignal,
    },
  );
}

function addInvestorsToInvestorPortfolio(
  investorIds: number[],
  portfolioId: number,
): Promise<AxiosResponse<number[]>> {
  return axios.post(`/investorPortfolio/${portfolioId}`, investorIds);
}

function addInvestorsToPortfolios(
  investorIDs: number[],
  portfolioIDs: number[],
) {
  return axios.post("/investorPortfolio/addInvestors", {
    investorIDs,
    portfolioIDs,
  });
}

function bulkAddInvestorsToPortfolios(
  portfolioId: number,
  mainFilter: MainFilter,
) {
  return axios.post(`/investorPortfolio/bulk-add/${portfolioId}`, mainFilter);
}

function getAffinityLink(id: number): Promise<AxiosResponse<AffinityLink>> {
  return axios.get(`/affinity/getLink/${id}`);
}

function getAffinityFields(
  affinityListID?: number,
): Promise<AxiosResponse<AffinitySyncField[]>> {
  return axios.get(
    `/affinity/fields${affinityListID ? `/${affinityListID}` : ""}`,
  );
}

function connectAffinityList(
  affinityLink: AffinityLink,
): Promise<AxiosResponse<AffinityLink>> {
  return axios.post("/affinity/linkAndSync", affinityLink);
}

function pushAffinityList(
  affinityLink: AffinityLink,
): Promise<AxiosResponse<boolean>> {
  return axios.post("/affinity/push", affinityLink);
}

function getAffinityColumnMapping(
  id: number,
): Promise<AxiosResponse<PushableColumn[]>> {
  return axios.get(`/affinity/defaultMapping/${id}`);
}

function pushSingleCompany(
  affinityLink: PushCompanyToAffinity,
): Promise<AxiosResponse<boolean>> {
  return axios.post("/affinity/pushSingleCompany", affinityLink);
}

export default {
  getWebCustomerPortfolios,
  createTbPortfolio,
  deleteTbPortfolio,
  removeCompanyFromPortfolio,
  addCompaniesToPortfolios,
  getWebCustomerWcPortfolios,
  bulkAddCompaniesToPortfolios,
  bulkAddFundingRoundsCompaniesToPortfolios,
  renameTbPortfolio,
  syncAffinityList,
  getAffinityLists,
  getInvestorPortfolios,
  createInvestorPortfolio,
  deleteInvestorPortfolio,
  getWCInvestorPortfolios,
  addInvestorsToInvestorPortfolio,
  bulkAddInvestorsToPortfolios,
  addCompanyToPortfolio,
  addInvestorsToPortfolios,
  getAffinityFields,
  connectAffinityList,
  pushAffinityList,
  getAffinityLink,
  pushSingleCompany,
  getAffinityColumnMapping,
};
