import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b33eecd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-information-group" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  "data-testid": "appInformationGroupExplanation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInformation = _resolveComponent("AppInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'app-information-group__label',
          { 'app-information-group__label--line': _ctx.showLine },
        ]),
        "data-testid": "appInformationGroupLabel"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass([
            'app-information-group__label__title',
            { 'app-information-group__label__title--required': _ctx.isRequired },
          ]),
          style: _normalizeStyle(_ctx.titleStyle)
        }, _toDisplayString(_ctx.label), 7),
        _renderSlot(_ctx.$slots, "trailing", {}, undefined, true),
        (_ctx.hasTooltipContent)
          ? (_openBlock(), _createBlock(_component_AppInformation, {
              key: 0,
              content: _ctx.information,
              "data-testid": "appInformationGroupLabelInfo",
              "allow-html": _ctx.allowHtml
            }, null, 8, ["content", "allow-html"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.explanation)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.allowHtml)
              ? (_openBlock(), _createElementBlock("small", {
                  key: 0,
                  "data-testid": "appInformationGroupExplanation",
                  innerHTML: _ctx.explanation
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.explanation), 1))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}