<template>
  <div class="app-tooltip" ref="appTooltip">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import tippy, { Instance, hideAll } from "tippy.js";

export default defineComponent({
  name: "AppTooltip",

  // eslint-disable-next-line
  components: { AppLink },

  props: {
    content: {
      type: String,
      required: true,
    },
    allowHtml: {
      type: Boolean,
      default: false,
    },
    hideOnClick: {
      type: Boolean,
      default: true,
    },
    appendTo: {
      type: Object as PropType<"parent" | Element>,
      default: document.body,
    },
    showIf: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [Number, String],
      default: 350,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    content(newContent: string) {
      if (this.instance) {
        this.instance.destroy();

        this.instance = this.createTippyInstance(newContent);
      }
    },
  },

  mounted() {
    if (this.showIf && this.content.length) {
      this.instance = this.createTippyInstance(this.content);
    }
  },

  methods: {
    createTippyInstance(content: string) {
      return this.tippy(this.$refs.appTooltip as HTMLDivElement, {
        animation: "shift-away-subtle",
        hideOnClick: this.hideOnClick,
        theme: "app-tooltip-light",
        allowHTML: this.allowHtml,
        appendTo: this.appendTo,
        interactiveBorder: 4,
        interactive: true,
        arrow: this.arrow,
        content,
        offset: [this.offsetX, this.offsetY],
        maxWidth: this.maxWidth,
        onShow(instance) {
          hideAll({ exclude: instance });
        },
      });
    },

    tippy,
  },

  data() {
    return {
      instance: undefined as Instance<unknown> | undefined,
    };
  },
});
</script>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/dist/border.css";
@import "tippy.js/animations/shift-away-subtle.css";

.app-tooltip {
  width: fit-content;
  height: fit-content;
  display: flex;
}

.tippy-box[data-theme~="app-tooltip-light"] {
  background-color: $white;
  color: $gray;
  border: 1px solid $border;
  overflow-wrap: break-word;
  z-index: 4;
  text-align: left;
  font-size: rem(12px);
  line-height: rem(24px);
}

.tippy-content {
  overflow: hidden;
}

.tippy-arrow {
  color: $white;
  border-color: $border;
}
</style>
