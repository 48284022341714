export const invalidEmailMessage = "You have entered an invalid email address.";
export const invalidBusinessEmailMessage = "Only business emails are allowed.";
export const invalidPasswordMessage =
  "Your password must contain at least one uppercase letter, at least one lowercase letter, at least one number, at least one special character and be at least 8 characters long.";
export const passwordMismatch = "The two passwords do not match";
export const invalidEmptyMessage = "The field is empty.";
export const invalidEmptyFieldsMessage =
  "Please complete all fields before login in.";
export const termsError =
  "Please accept Net Zero Insights's Terms of Use and Privacy Policy.";
export const defaultServerErrorMessage =
  "Something went wrong. Please, try again.";
export const confirmErrorMessage =
  "There was an error managing your request, please try again later.";
export const serverError =
  "An error has occurred, please try again later and if the problem persists, please contact us at info@netzeroinsights.com";
