import axios from "@/plugins/axios/axios";
import {
  UserTeamRequest,
  WebCustomerRequestFilter,
  WebCustomerTableUser,
} from "@/shared/types/admin";
import { UserTeam } from "@/shared/types/common";
import { AxiosResponse } from "axios";

const getWebCustomerList = (
  filter: WebCustomerRequestFilter,
  abortSignal?: AbortSignal,
): Promise<
  AxiosResponse<{
    items: WebCustomerTableUser[];
    count: number;
  }>
> => {
  return axios.post("/webcustomers/filter", filter, {
    signal: abortSignal,
  });
};

const webCustomerSignIn = (
  webCustomerID: number,
): Promise<AxiosResponse<boolean>> => {
  return axios.get(`/webcustomers/loginAs/${webCustomerID}`);
};

const disableWebCustomer = (id: number): Promise<AxiosResponse<boolean>> => {
  return axios.put(`/webcustomers/disable/${id}`);
};

const updateWebCustomer = (
  id: number,
  data: WebCustomerTableUser,
): Promise<AxiosResponse<WebCustomerTableUser>> => {
  return axios.put(`/webcustomers/${id}`, data);
};

const createWebCustomer = (
  data: WebCustomerTableUser,
): Promise<AxiosResponse<WebCustomerTableUser>> => {
  return axios.post("/webcustomers", data);
};

const getUserTeams = (
  user: UserTeamRequest,
  abortSignal?: AbortSignal,
): Promise<
  AxiosResponse<{
    count: number;
    items: UserTeam[];
  }>
> => {
  return axios.post("/teams/filter", user, {
    signal: abortSignal,
  });
};

const updateTeam = (user: UserTeam): Promise<AxiosResponse<UserTeam>> => {
  return axios.put(`/teams/${user.id}`, user);
};

const deleteTeam = (id: number): Promise<AxiosResponse<boolean>> => {
  return axios.delete(`/teams/${id}`);
};

const createTeam = (team: UserTeam): Promise<AxiosResponse<UserTeam>> => {
  return axios.post("/teams", team);
};

const reviewMarkInvestor = (
  requestId: number,
): Promise<AxiosResponse<string>> => {
  return axios.put(`/review/convert/${requestId}`);
};

export default {
  getWebCustomerList,
  webCustomerSignIn,
  disableWebCustomer,
  updateWebCustomer,
  createWebCustomer,
  getUserTeams,
  updateTeam,
  deleteTeam,
  createTeam,
  reviewMarkInvestor,
};
