<template>
  <div :class="appInputClass" :style="appInputStyle">
    <slot name="leading">
      <AppIcon :src="leading" :size="leadingSize" v-if="leading" />
    </slot>
    <input
      :type="getType"
      v-bind="$attrs"
      v-model="model"
      @focus="focus"
      @focusout="unFocus"
      @keyup.enter="handelEnter"
      data-testid="appInput"
      class="app-input__input"
      ref="input"
    />
    <slot name="trailing">
      <AppButton
        v-if="isPasswordType || isMasked"
        :icon="getPasswordIcon"
        padding="0"
        type="empty"
        @click="togglePasswordVisibility"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  StyleValue,
  defineAsyncComponent,
} from "vue";
import { AppInputType } from "@/shared/enums/components";
import { hidePassword, showPassword } from "@/shared/constants/icons";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";

export default defineComponent({
  name: "AppInput",

  components: {
    AppButton: defineAsyncComponent(
      () => import("@/components/app/AppButton/AppButton.vue"),
    ),
    AppIcon,
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    borderRadius: {
      type: String,
      default: "16px",
    },
    width: String,
    height: String,
    margin: String,
    error: Boolean,
    leading: String,
    leadingSize: String,
    type: {
      type: String as PropType<AppInputType>,
      default: AppInputType.TEXT,
    },
    maxLength: Number,
    isMasked: Boolean,
  },

  emits: ["update:modelValue", "focus", "enter", "unFocus"],

  data() {
    return {
      isFocused: false,
      isPasswordVisible: false,
    };
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(newModelValue: string | number) {
        if (this.maxLength && String(newModelValue).length > this.maxLength) {
          return ((this.$refs.input as HTMLInputElement).value = this
            .modelValue as string);
        }

        this.$emit("update:modelValue", newModelValue);
      },
    },

    appInputStyle(): StyleValue {
      return {
        width: this.width,
        height: this.height,
        borderRadius: this.borderRadius,
        margin: this.margin,
      };
    },

    appInputClass() {
      return [
        "app-input",
        {
          "app-input--disabled":
            this.$attrs.disabled !== undefined &&
            this.$attrs.disabled !== false,
          "app-input--focused": this.isFocused,
          "app-input--error": this.error,
        },
      ];
    },

    isPasswordType(): boolean {
      return this.type === AppInputType.PASSWORD;
    },

    getType(): AppInputType {
      if (this.isPasswordType && this.isPasswordVisible) {
        return AppInputType.TEXT;
      }

      return this.type;
    },

    getPasswordIcon(): string {
      return this.isPasswordVisible ? showPassword : hidePassword;
    },

    getInputMaskedStyle(): string {
      return this.isMasked && !this.isPasswordVisible ? "disc" : "unset";
    },
  },

  methods: {
    focus(event: MouseEvent) {
      this.isFocused = true;

      this.$emit("focus", event);
    },

    unFocus(event: MouseEvent) {
      this.isFocused = false;

      this.$emit("unFocus", event);
    },

    handelEnter() {
      this.$emit("enter");
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-input {
  display: flex;
  gap: rem(8px);
  padding: rem(4px) rem(8px);
  border: rem(1px) solid $border;
  background-color: $white;
  align-items: center;

  &:hover {
    border-color: $primary;
  }
}

.app-input--focused {
  border-color: $primary;
}

.app-input--error {
  border-color: $danger;
}
.app-input--disabled {
  background-color: $disabled;
  border-color: $disabled;
}

.app-input input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  appearance: none;
  background: unset;
  -webkit-appearance: none;
  -webkit-text-security: v-bind(getInputMaskedStyle);

  &:autofill,
  &:-webkit-autofill:is(:hover, :focus, :active) {
    -webkit-box-shadow: 0 0 0 rem(30px) $white inset;
    box-shadow: 0 0 0 rem(30px) $white inset;
  }
}

.app-input input::-webkit-outer-spin-button,
.app-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app-input input[type="number"] {
  -moz-appearance: textfield;
}
</style>
