
import {
  defineComponent,
  PropType,
  StyleValue,
  defineAsyncComponent,
} from "vue";
import { AppInputType } from "@/shared/enums/components";
import { hidePassword, showPassword } from "@/shared/constants/icons";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";

const __default__ = defineComponent({
  name: "AppInput",

  components: {
    AppButton: defineAsyncComponent(
      () => import("@/components/app/AppButton/AppButton.vue"),
    ),
    AppIcon,
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    borderRadius: {
      type: String,
      default: "16px",
    },
    width: String,
    height: String,
    margin: String,
    error: Boolean,
    leading: String,
    leadingSize: String,
    type: {
      type: String as PropType<AppInputType>,
      default: AppInputType.TEXT,
    },
    maxLength: Number,
    isMasked: Boolean,
  },

  emits: ["update:modelValue", "focus", "enter", "unFocus"],

  data() {
    return {
      isFocused: false,
      isPasswordVisible: false,
    };
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },

      set(newModelValue: string | number) {
        if (this.maxLength && String(newModelValue).length > this.maxLength) {
          return ((this.$refs.input as HTMLInputElement).value = this
            .modelValue as string);
        }

        this.$emit("update:modelValue", newModelValue);
      },
    },

    appInputStyle(): StyleValue {
      return {
        width: this.width,
        height: this.height,
        borderRadius: this.borderRadius,
        margin: this.margin,
      };
    },

    appInputClass() {
      return [
        "app-input",
        {
          "app-input--disabled":
            this.$attrs.disabled !== undefined &&
            this.$attrs.disabled !== false,
          "app-input--focused": this.isFocused,
          "app-input--error": this.error,
        },
      ];
    },

    isPasswordType(): boolean {
      return this.type === AppInputType.PASSWORD;
    },

    getType(): AppInputType {
      if (this.isPasswordType && this.isPasswordVisible) {
        return AppInputType.TEXT;
      }

      return this.type;
    },

    getPasswordIcon(): string {
      return this.isPasswordVisible ? showPassword : hidePassword;
    },

    getInputMaskedStyle(): string {
      return this.isMasked && !this.isPasswordVisible ? "disc" : "unset";
    },
  },

  methods: {
    focus(event: MouseEvent) {
      this.isFocused = true;

      this.$emit("focus", event);
    },

    unFocus(event: MouseEvent) {
      this.isFocused = false;

      this.$emit("unFocus", event);
    },

    handelEnter() {
      this.$emit("enter");
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5dda9716": (_ctx.getInputMaskedStyle)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__