import axios from "axios";
import router from "@/router";
import { HttpStatus } from "@/shared/enums/HttpStatus";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export default instance;
