import { Currency, UserTeam } from "@/shared/types/common";

export interface FundingRoundModel {
  addedInvestors: number[];
  addedNews: null | NewNews[];
  addingNewOne: boolean;
  clientId: number | null;
  coFundingRoundId: number | null;
  deletedInvestors: number[];
  deletedNews: number[];
  id: number | null;
  originalRoundAmount: number | null;
  roundAmount: number | null;
  roundAmountUSD: number | null;
  roundCurrency?: string;
  roundDate: string | null;
  roundInvestors: number[];
  roundNews: number[];
  roundType?: string;
  source: string;
  valuationType: string | null;
  valuationCurrency?: string;
  valuationAmount: number | null;
  financingType: string | null;
}

export interface NewNews {
  title?: string;
  url: string;
}

export interface AddInvestorModel {
  name: string;
  website: string;
  linkedInURL: string;
  pitchbookURL?: string;
  twitterURL?: string;
  primaryTypeID?: number;
  secondaryTypeIDs?: number[];
}

export interface EditableFundingRoundNews {
  fundingRoundId?: number;
  googleTitle?: string;
  id: number | string;
  url: string;
}

export interface EditableExternalProvider {
  provider: string;
  link: string;
}

export interface ExternalProviders {
  cbInsights: string;
  crunchbase: string;
  dealroom: string;
  i3: string;
  linkedIn: string;
  owler: string;
  pitchbook: string;
}

export interface WebCustomerRequestFilter {
  role?: string;
  name?: string;
  surname?: string;
  email?: string;
  website?: string;
  startDate?: Date;
  endDate?: Date;
  export?: boolean;
  limit?: number;
  offset?: number;
  phone?: string;
  freeTrial?: boolean;
  preview?: boolean;
  internal?: boolean;
  enabled?: boolean;
}

export interface WebCustomerTableUser {
  id: number;
  role: string;
  name: string;
  surname: string;
  password?: string;
  website: string;
  email: string;
  phone: string;
  team?: UserTeam;
  startDate: string;
  endDate: string;
  acquisitionDate: string;
  admin: boolean;
  freeTrial: boolean;
  preview: boolean;
  enabled: boolean;
  internal: boolean;
  export: boolean;
  trustedSource: boolean;
  regexpFilterEnabled: boolean;
  pageSize?: number;
  selectedCurrency?: Currency;
}

export enum EditOrganizationButtonName {
  NOT_STARTUP,
  INACTIVE,
  NOT_GREEN,
  DUPLICATED,
  CONFIRM_COMPANY_INFO,
  CONFIRM_ROUND_INFO,
}

export const ADMIN_PANEL_TABS = {
  STARTUP_SUBMISSION: "startups-submission",
  TAXONOMY_ITEMS: "taxonomy-items",
  TAXONOMY_TYPES: "taxonomy-tag-types",
  TAXONOMY_FAMILIES: "taxonomy-families",
  TAXONOMY_RELATIONS: "taxonomy-relations",
  TAXONOMY_TAGS: "taxonomy-tags",
  WEB_CUSTOMERS: "web-customers",
  MARKET_REPORT: "market-report",
  DASHBOARD_REPORT: "dashboard-report",
  USER_TEAMS: "user-teams",
};

export interface UserTeamRequest {
  name?: string;
  domain?: string;
  limit: number;
  offset: number;
}
