import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3eb1d72e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.appInputClass),
    style: _normalizeStyle(_ctx.appInputStyle)
  }, [
    _renderSlot(_ctx.$slots, "leading", {}, () => [
      (_ctx.leading)
        ? (_openBlock(), _createBlock(_component_AppIcon, {
            key: 0,
            src: _ctx.leading,
            size: _ctx.leadingSize
          }, null, 8, ["src", "size"]))
        : _createCommentVNode("", true)
    ], true),
    _withDirectives(_createElementVNode("input", _mergeProps({ type: _ctx.getType }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
      onFocusout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.unFocus && _ctx.unFocus(...args))),
      onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handelEnter && _ctx.handelEnter(...args)), ["enter"])),
      "data-testid": "appInput",
      class: "app-input__input",
      ref: "input"
    }), null, 16, _hoisted_1), [
      [_vModelDynamic, _ctx.model]
    ]),
    _renderSlot(_ctx.$slots, "trailing", {}, () => [
      (_ctx.isPasswordType || _ctx.isMasked)
        ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            icon: _ctx.getPasswordIcon,
            padding: "0",
            type: "empty",
            onClick: _ctx.togglePasswordVisibility
          }, null, 8, ["icon", "onClick"]))
        : _createCommentVNode("", true)
    ], true)
  ], 6))
}