<template>
  <div class="app-floating-menu" ref="appFloatingMenu">
    <slot name="content" />
    <ul
      :class="appFloatingMenuListClass"
      :style="appFloatingMenuListStyle"
      v-if="modelValue"
    >
      <AppFloatingMenuItem
        v-for="option in options"
        :item="option"
        :key="'app-floating-menu-list-option-' + option.name"
        @click="handleOptionClick(option)"
      />
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, StyleValue } from "vue";
import {
  AppFloatingMenuOption,
  AppFloatingMenuSide,
} from "@/shared/types/components";
import AppFloatingMenuItem from "../AppFloatingMenuItem/AppFloatingMenuItem.vue";

export default defineComponent({
  name: "AppFloatingMenu",

  components: { AppFloatingMenuItem },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array as PropType<AppFloatingMenuOption[]>,
      required: true,
    },
    side: {
      type: String as PropType<AppFloatingMenuSide>,
      default: "right",
    },
  },

  emits: ["update:modelValue", "select"],

  mounted() {
    window.addEventListener("click", this.handleWindowClick);
  },

  beforeUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  },

  computed: {
    appFloatingMenuListClass() {
      return ["app-floating-menu-list", `app-floating-menu-list--${this.side}`];
    },
    appFloatingMenuListStyle(): StyleValue {
      return {
        left: this.side === "left" ? 0 : undefined,
        right: this.side === "right" ? 0 : undefined,
        top: `${
          (this.$refs.appFloatingMenu as HTMLDivElement)?.clientHeight || 20 + 8
        }px`,
      };
    },
  },

  methods: {
    handleWindowClick(event: MouseEvent) {
      const target = event.target as Node;
      const appFloatingMenu = this.$refs.appFloatingMenu as HTMLDivElement;

      if (this.modelValue && !appFloatingMenu.contains(target)) {
        this.$emit("update:modelValue", false);
      }
    },

    handleOptionClick(option: AppFloatingMenuOption) {
      if (!option.isDisabled) {
        this.$emit("select", option);
        this.$emit("update:modelValue", false);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-floating-menu {
  position: relative;
  width: fit-content;
}

.app-floating-menu-list {
  background-color: $white;
  border: 1px solid $border;
  border-radius: rem(16px);
  position: absolute;
  top: rem(8px);
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: max-content;
  z-index: 10;
}

.app-floating-menu-list--right {
  border-top-right-radius: 0;
}

.app-floating-menu-list--left {
  border-top-left-radius: 0;
}
</style>
